import React, { useState, useEffect } from "react";

import { Navigation } from "./navigation";
import { Header } from "./header";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import {TestimonialCarousel} from "./TestimonialCarousel";
import { CarouselFlayers } from "./carouselFlayers";
import JsonData from "../data/data.json";



export const Header_root = (props) => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
      <div>
        <Navigation />
        <Header data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <CarouselFlayers data={landingPageData.flayers} />
        <Services data={landingPageData.Services} />
        <Team data={landingPageData.Team} />
        <Testimonials data={landingPageData.Testimonials} />
        <Gallery data={landingPageData.calendarios} />
        <Gallery data={landingPageData.stickers} />
        <Gallery data={landingPageData.tazas} />
        <Gallery data={landingPageData.playeras} />
        <Contact data={landingPageData.Contact} />
      </div>
    );
  };