import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import { Gallery } from "./gallery";

export const Educacion = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);
  return (
    <div>
      <Navigation/>
      <Header/>
      <Gallery data={landingPageData.Gallery11} />
      <Gallery data={landingPageData.Gallery10} />
      <Gallery data={landingPageData.Gallery9} />   
      <Gallery data={landingPageData.Gallery8} />    
      <Contact/>

    </div>
  );
};

