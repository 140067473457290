import React from 'react';
import {TestimonialCarousel} from './TestimonialCarousel'; // Asegúrate de ajustar la ruta a tu componente

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Nuestras Aliadas</h2>
          <h5>Mujeres que hacen posible la red de Felpudas Teatro.</h5>
        </div>
        <div className="row">
          {props.data
            ? (
              <div className="col-md-12">
                <TestimonialCarousel data={props.data} />
              </div>
            )
            : "loading"}
        </div>
      </div>
    </div>
  );
};
