import React from 'react';
import { CustomCarousel } from './customCarousel'; // Asegúrate de ajustar la ruta a tu componente

export const CarouselFlayers = (props) => {
  return (
    <div id="carouselFlayers">
      <div className="container">
        <div className="section-title text-center">
        </div>
        <div className="row">
          {props.data
            ? (
              <div className="col-md-12">
                <CustomCarousel data={props.data} />
              </div>
            )
            : "loading"}
        </div>
            <br></br>
            <br></br>
      </div>
    </div>
  );
};
