import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const TestimonialCarousel = (props) => {
  return (
    <div className=''>
      <Carousel  showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} 
        infiniteLoop={true} autoPlay={true} dynamicHeight={false}
        width="50%" centerMode={false}
        className='carousel-style'>
        {props.data
          ? (
            props.data.map((testimonial, index) => (
              <div key={index} >
                <img src={testimonial.img} alt={testimonial.name} 
                style={{
                  maxWidth: '500px',
                  maxHeight: '500px',
                  width:"100%",
                  height:"auto",
                  objectFit:"cover"
                }}
                />
                {/* <p className="legend">{testimonial.name}</p> */}
                {testimonial.text ? 
                   <p className='' ><strong>{testimonial.name}</strong>.  {testimonial.text}</p>
                  : ""
                }
              </div>
            ))
          ) : "loading..."
        }
      </Carousel>
    </div>
   
  );
};

// export default TestimonialCarousel;
