import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import { Gallery } from "./gallery";

export const Artistic_prod = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);
  return (
    <div>
      <Navigation/>
      <Header/>   
      <Gallery data={landingPageData.Gallery6} />
      <Gallery data={landingPageData.Gallery4} /> 
      <Gallery data={landingPageData.Gallery2} /> 
      <Gallery data={landingPageData.Gallery12} /> 
      <Gallery data={landingPageData.Gallery7} /> 
      <Contact/>

    </div>
  );
};
