import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

// Nuevos componenetes para las rutas extras
import { Artistic_prod } from "./components/artistic_prod";
import { Header_root} from "./components/header_root"
import { Educacion } from "./components/educacion";
import { Intervencion } from "./components/intervencion";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Routes> {/* Aqui se definen las nuevas rutas */}
          <Route path="/" element={<Header_root />} />
          <Route path="/artistic-prod" element={<Artistic_prod />} />
          <Route path="/educacion" element={<Educacion/>}/>
          <Route path="/intervencion" element={<Intervencion/>}/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
