import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.title : "loading..."}</h2>
          <h5>{props.data ? props.data.paragraphRecursos : "loading..."}</h5>
          <h5>{props.data ? props.data.paragraphRecursos2 : "loading..."}</h5>
          <h3>{props.data ? props.data.title2 : "loading..."}</h3>
          <p>{props.data ? props.data.diseno : "loading..."}</p>
          <p>{props.data ? props.data.diseno2 : "loading..."}</p>
          <h4>{props.data ? props.data.title3 : "loading..."}</h4>
          <p>{props.data ? props.data.acuerpamiento : "loading..."}</p>
          <p>{props.data ? props.data.acuerpamiento2 : "loading..."}</p>
          <p>{props.data ? props.data.acuerpamiento3 : "loading..."}</p>
          <h4>{props.data ? props.data.title4 : "loading..."}</h4>
          <p>{props.data ? props.data.asesoria : "loading..."}</p>
          <p>{props.data ? props.data.actrices : "loading..."}</p>
          <p>{props.data ? props.data.produccion : "loading..."}</p>
          <p>{props.data ? props.data.creditos : "loading..."}</p>
          <p>{props.data ? props.data.paragraph : "loading..."}</p>
          <p>{props.data ? props.data.flores : "loading..."}</p>
          <p>{props.data ? props.data.flores1 : "loading..."}</p>
          <p>{props.data ? props.data.flores2 : "loading..."}</p>
          <p>{props.data ? props.data.acuerpamiento4 : "loading..."}</p>
          <a href="https://www.youtube.com/embed/GUEIBc5RkzI?si=LqBayKL96ivdADGO" target="_blank" rel="noopener noreferrer">
              <h4>{props.data ? props.data.enlacePerifericas : "loading..."}</h4>
          </a>      
           {/* {props.data.enlacePerifericas ? '<iframe width="560" height="315" src="https://www.youtube.com/embed/GUEIBc5RkzI?si=3uqsqtZIwqWzL0Ua" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'
                                            : ""}    */}
            {/* <ul>
            {props.data
              ? props.data.lista.map((item, index) => (
              <li key={index}>{item}</li>))
              : "Loading..."}
          </ul> */}
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.imagenes.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
