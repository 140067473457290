import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                {/* <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeLLCSCHQc6cQNSUXm7WDnFFAnrh1F5uHasFlnw6gIfk7ulNw/viewform?usp=pp_url"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  únete a nosotras
                </a> */}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

