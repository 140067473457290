import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="" >
        <div style={{  alignItems: 'center' }}>
          <a className="navbar-brand page-scroll" href="/#page-top"  style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="img/favicon.ico.png "
              alt="Logo"
              style={{
                maxWidth: '100px',
                maxHeight: '50px',
                marginRight: '10px',
              }}
            />
            <p className="font-google">FELPUDAS TEATRO</p>
          </a>
        
          
        
        
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/#about" className="page-scroll">
                Nosotras
              </a>
            </li>
            <li>
              <a href="/#services" className="page-scroll">
                Líneas de trabajo
              </a>
            </li>
            <li>
              <a href="/#team" className="page-scroll">
                Fundadoras
              </a>
            </li>
            <li>
              <a href="/#testimonials" className="page-scroll">
                Aliadas
              </a>
            </li>
            <li>
              <a href="/#portfolio" className="page-scroll">
                Recursos de Autogestión
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contáctanos
              </a>
            </li>
          </ul>
        </div>
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
        >
          {" "}
          <span className="sr-only">Toggle navigation</span>{" "}
          <span className="icon-bar"></span>{" "}
          <span className="icon-bar"></span>{" "}
          <span className="icon-bar"></span>{" "}
        </button>
        </div>
      </div>
    </nav>
  );
};
