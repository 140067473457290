import React, { useState } from 'react';

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Líneas de trabajo</h2>
          <p>
          Tenemos tres líneas de trabajo que se sustentan bajo la consigna de que el teatro es acción social y política; fundamentando nuestro hacer con investigación social, artística y el trabajo popular desde los feminismos.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                      <a href={d.url} rel="noopener noreferrer">
                      <i className={d.icon}></i>
                      </a>
                  <div className="service-desc">
                    <h3>
                      {d.name}
                    </h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

// export const Services = (props) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalContent, setModalContent] = useState('');

//   const openModal = (content) => {
//     console.log('Clic en h3');
//     setIsModalOpen(true);
//     setModalContent(content);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setModalContent('');
//   };

//   return (
//     <div id="services" className="text-center">
//       <div className="container">
//         <div className="section-title">
//           <h2>Líneas de trabajo</h2>
//           <p>
//           Tenemos tres línea de trabajo que se sustentan bajo la consigna de que el teatro es acción social y política; fundamentando nuestro hacer con investigación social, artística y el trabajo popular desde los feminismos.
//           </p>
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-md-4">
//                   <i className={d.icon}></i>
//                   <div className="service-desc">
//                     <h3 onClick={() => openModal(d.text)}>{d.name}</h3>
//                     <p>{d.text}</p>
//                   </div>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>

//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={closeModal}>
//               &times;
//             </span>
//             {modalContent}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };


