import React from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Contact } from "./contact";

export const Intervencion = (props) => {
  return (
    <div>
      <Navigation />
      <Header />
      <div className="title-container">
        <h2>Estamos trabajando para hacer posible el trabajo con la comunidad.</h2>
        <h2>¡Pronto más detalles!</h2>
        <h2>¡Espéralo!</h2>
      </div>
      <Contact />
    </div>
  );
};

