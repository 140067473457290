import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="">
        <div className="row about-text">
          <h2>¿Quiénes somos?</h2>
          <img src="img/about.png" className="img-responsive col-md-1" alt="" />{" "}
          <p className="text-justify">{props.data ? props.data.paragraph : "loading..."}</p>
          <p className="text-justify">{props.data ? props.data.paragraph2 : "loading..."}</p>
          <p className="text-justify">{props.data ? props.data.paragraph3 : "loading..."}</p>
          <p className="text-justify">{props.data ? props.data.paragraph4 : "loading..."}</p>

        </div>
      </div>
    </div>
  );
};
