import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Fundadoras</h2>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className=" team col-md-12" style={{ alignItems: 'center' }}>
                  <div className="thumbnail col-md-4">
                    <img src={d.img} alt="..." className="team-img img-responsive" />
                  </div>
                  <div className="col-md-8">
                  <div className="">
                      <h4>{d.name}</h4>
                      <h6>{d.job}</h6>
                    </div>
                    <p className="text-justify">{d.paragraph}</p>
                    <p className="text-justify">{d.paragraph2}</p>
                    <p className="text-justify">{d.paragraph3}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
